import type { DateTime } from 'luxon';
import type {
  AddressLocation,
  CommonAddress,
  StreetAddress,
} from '@wix/ambassador-wix-atlas-service-web/types';
import type {
  DurationRange,
  FulfillmentDetails,
  FulfillmentTimeSlot,
} from '@wix/ambassador-restaurants-operations-v1-operation/types';
import type { AvailabilityStatus } from '@wix/ambassador-restaurants-menu-settings-v1-menu-ordering-settings/types';

export type MinMaxRange = {
  min: Minute;
  max: Minute;
};

export type Money = {
  amount: number;
  currency: string;
};

export const BusinessOpenStatus = {
  Open: 'Open',
  Closed: 'Closed',
  ClosingSoon: 'ClosingSoon',
  OpeningSoon: 'OpeningSoon',
};

export type DispatchTime = {
  from: DateTime;
  until: DateTime;
};

export type Address = CommonAddress;
export type AddressInputAddress = {
  formatted: string;
  addressLine?: string;
  location?: AddressLocation;
  streetAddress?: StreetAddress;
  city?: string;
  subdivision?: string;
  country?: string;
  postalCode?: string;
  subdivisions?: string[];
};

export enum SchedulingType {
  ASAP = 'ASAP',
  ASAP_AND_FUTURE = 'ASAP_AND_FUTURE',
  PRE_ORDER = 'PRE_ORDER',
}

export enum DispatchType {
  /** Pickup fulfillment */
  PICKUP = 'PICKUP',
  /** Delivery fulfillment */
  DELIVERY = 'DELIVERY',
}

export type OLOWidgetState = 'errorState' | 'olo';
export type MenuState = 'menuEmptystate' | 'menu';
export type SectionState = 'sectionEmptystate' | 'sections' | 'loadingSection';
export type DishesWidgetState = 'dishesEmpty' | 'dishes' | 'loaderstate';
export type DishState = 'outofStock' | 'regular';
export type ModifierRuleState = 'box14' | 'box13';
export type MenusAvailabilityStatus = { [key: string]: AvailabilityStatus };
export type TimeSlotsPerMenu = Record<string, Record<string, FulfillmentTimeSlot>>;

export const MENU_STATES: Record<string, MenuState> = {
  menuEmptystate: 'menuEmptystate',
  menu: 'menu',
};

export const SECTION_STATES: Record<string, SectionState> = {
  loadingState: 'loadingSection',
  sectionEmptystate: 'sectionEmptystate',
  sections: 'sections',
};

export const DISHES_WIDGET_STATES: Record<string, DishesWidgetState> = {
  loading: 'loaderstate',
  dishesEmpty: 'dishesEmpty',
  dishes: 'dishes',
};

export const DISH_STATES: Record<string, DishState> = {
  unavailable: 'outofStock',
  regular: 'regular',
};

export const MODIFIER_RULE_STATES: Record<string, ModifierRuleState> = {
  singleSelect: 'box14',
  multiSelect: 'box13',
};

export type AsapScheduling = {
  maxInMinutes?: Minute;
  rangeInMinutes?: MinMaxRange;
};

export type PreorderScheduling = {
  timeWindowDuration: Minute;
  timeInAdvance: MinMaxRange;
};

export type Minute = number;
export type Timestamp = number;
export type OperationType = 'PRE_ORDER' | 'ASAP';

export type BaseOperation = {
  id: string;
  name: string;
};

export type Operation = BaseOperation & {
  enabled: boolean;
  fulfillmentIds: string[];
  asapOptions?: AsapScheduling;
  allowAsapFutureHandling?: boolean;
  businessDaysAheadHandlingOptions?: number;
  operationType: OperationType;
  preOrderOptions?: PreorderScheduling;
  defaultDispatchType?: DispatchType;
  operationGroupId: string;
  locationId: string;
  locationDetails?: OSLocation;
};

export type OperationGroup = BaseOperation & {};

export type OSLocation = {
  name: string;
  address?: Address;
  default?: boolean;
};

export type TimeSlot = {
  id: string;
  startTime: DateTime;
  endTime: DateTime;
  dispatchType: DispatchType;
  startsNow?: boolean;
  fulfillmentDetails: FulfillmentDetails;
};

export type DispatchInfo = {
  address?: Address;
  selectedTimeSlot?: TimeSlot;
  maxTimeOptions?: number;
  durationRangeOptions?: DurationRange;
  minOrder?: string;
  deliveryFee?: string;
  freeFulfillmentPriceThreshold?: string;
  minDate?: DateTime;
};

export type DispatchesInfo = Record<DispatchType, DispatchInfo>;

export type DispatchState = {
  selectedDispatchType: DispatchType;
  dispatchesInfo: DispatchesInfo;
  configuredDispatchTypes: DispatchType[];
  availableDispatchTypes: DispatchType[];
};

export type DispatchStateByLocation = Map<string, DispatchState>;
