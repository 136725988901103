/* eslint-disable no-console */
import type { EditorReadyFn, TFunction } from '@wix/yoshi-flow-editor';
import {
  disablePopupsAutoOpen,
  showInstallationProgressBar,
  hideInstallationProgressBar,
  setOrdersPageState,
  getEditorOptions,
  navigateToPageByPageRef,
  progressBarMoveToStepNumber,
  createFedopsLogger,
  createBILogger,
  syncPagesByOperations,
} from './editor.utils';
import {
  ecomComponentsConfiguration,
  handleFirstInstall,
  handleInstallationRetry,
  updatePage,
} from './installationUtils';
import { registerGfppEvents } from './gfppEvents';
import { SPECS } from 'root/appConsts/experiments';
import { OPTIMUS_MIGRATION_UNPUBLISH_ORIGIN_TYPE, PAGE_DATA } from 'root/appConsts/consts';
import { OperationsClient } from 'root/api/operationClient';
import { registerComponentChangeEvents } from './componentEvents';
import { withRetry } from 'root/utils/retryUtils';
import { EcomComponent, type EcomComponentConfiguration } from '@wix/ecom-platform-sdk';
import { APP_DEF_IDS } from '@wix/restaurants-consts';

export const editorReadyImpl: EditorReadyFn = async (editorSDK, appDefId, options, flowAPI) => {
  const BI_SUBJECT = 'OLO Installation';
  const { experiments, translations, httpClient } = flowAPI;
  const t = translations.t as TFunction;
  const msid = await editorSDK?.info.getMetaSiteId('_token');
  const biReporterService = createBILogger({ flowAPI, options });

  try {
    console.log('Online orders - editor ready start.', { msid, appDefId, origin: options.origin });
    const fedopsLogger = await createFedopsLogger({ editorSDK, flowAPI, biReporterService });
    const { isFirstInstall, isResponsive, isStudio } = getEditorOptions(options);
    let shouldOpenProgressBar = !isResponsive;
    const isOptimusOrigin =
      (options.origin.type as unknown) === OPTIMUS_MIGRATION_UNPUBLISH_ORIGIN_TYPE;
    ecomComponentsConfiguration.requireEcomComponents =
      async (): Promise<EcomComponentConfiguration> => {
        return {
          ecomComponents: {
            [EcomComponent.CHECKOUT]: {
              isInstalled: true,
            },
            [EcomComponent.THANK_YOU_PAGE]: {
              isInstalled: true,
            },
            [EcomComponent.CART]: {
              isInstalled: true,
            },
            [EcomComponent.CART_ICON]: {
              isInstalled: true,
            },
          },
          shouldInstallMembersArea: !experiments.enabled(SPECS.skipMemberAreaAutoInstall),
          managingAppId: APP_DEF_IDS.restaurants,
        };
      };

    try {
      isFirstInstall && (await showInstallationProgressBar(editorSDK, t, shouldOpenProgressBar));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log('closeProgressBar failed', e.message);
      shouldOpenProgressBar = false;
    }

    const enableOperationGroup = experiments.enabled(SPECS.enableOperationGroup);
    let operations;
    if (isFirstInstall) {
      await editorSDK.document.transactions.runAndWaitForApproval('token', async () => {
        fedopsLogger.restaurantsOrdersFirstInstallStarted();
        const { oloPageRef, menusPromise, lightboxesPromise, oloWidgetRef } =
          await handleFirstInstall({
            editorSDK,
            t,
            experiments,
            appDefId,
            msid,
            isResponsive,
            isStudio,
            fedopsLogger,
            biReporterService,
            subjectType: BI_SUBJECT,
            shouldOpenProgressBar,
            isOptimusOrigin,
          });

        await progressBarMoveToStepNumber(editorSDK, t, 2, shouldOpenProgressBar);
        await Promise.all([menusPromise, lightboxesPromise]);
        await navigateToPageByPageRef(editorSDK, oloPageRef);

        operations = await withRetry({
          fn: new OperationsClient(httpClient).getOperations,
          args: [],
          name: 'getOperations',
          maxTry: 5,
        });

        const [operation] = operations ?? [];
        if (operation) {
          const optimusMigration =
            (options.origin.type as unknown) === OPTIMUS_MIGRATION_UNPUBLISH_ORIGIN_TYPE;
          const pageId = enableOperationGroup ? operation?.operationGroupId : operation?.id;
          await updatePage({
            editorSDK,
            pageRef: oloPageRef,
            pageId: pageId ?? operation?.id,
            useDynamicUri: optimusMigration,
          });
          biReporterService?.reportOloEditorInstallationStepsEvent({
            step: 'installation_page_update',
            isFirstInstall,
            isStudio,
            msid,
            value: operation?.id,
          });
        } else {
          biReporterService?.reportOloEditorInstallationStepsEvent({
            step: 'installation_page_update_failed',
            isFirstInstall,
            isStudio,
            msid,
          });
        }
        fedopsLogger.restaurantsOrdersFirstInstallEnded();
        return oloPageRef;
      });
      await progressBarMoveToStepNumber(editorSDK, t, 3, shouldOpenProgressBar);
    } else {
      biReporterService?.reportOloEditorInstallationStepsEvent({
        step: 'installation_retry',
        isStudio,
        msid,
      });

      await handleInstallationRetry({
        editorSDK,
        t,
        experiments,
        appDefId,
        msid,
        isResponsive,
        isStudio,
        fedopsLogger,
        biReporterService,
      });
    }

    await syncPagesByOperations({
      editorSDK,
      flowAPI,
      fedopsLogger,
      biReporterService,
      isStudio,
      msid,
      operationList: operations,
      enableOperationGroup,
    });

    setOrdersPageState(editorSDK);

    await disablePopupsAutoOpen(editorSDK);

    // eslint-disable-next-line no-console
    console.log('Online orders - panels logic start...');
    registerGfppEvents(editorSDK, fedopsLogger, t, options.origin.type);

    // eslint-disable-next-line no-console
    console.log('Online orders - panels logic ended.');

    hideInstallationProgressBar(editorSDK, shouldOpenProgressBar);

    registerComponentChangeEvents({
      editorSDK,
      flowAPI,
      httpClient,
      fedopsLogger,
      pageIdPrefix: PAGE_DATA.pageId,
      reportError: flowAPI.reportError,
      sentry: flowAPI.errorMonitor,
      biLogger: biReporterService,
      msid,
      isStudio,
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    biReporterService?.reportOloEditorInstallationStepsEvent({
      step: 'error',
      msid,
      errorMessage: e.message,
    });

    // eslint-disable-next-line no-console
    console.error('Online orders - installation error: ', e, e.message);
  }
};
